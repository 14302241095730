import React from 'react'   
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const Nogo = () => { 
    return <NogoCss className="nogo-wrapper">
        <a href="https://www.newberry.org" target="_blank">
            <svg className="nogo" version="1.0"   viewBox="0 0 619 125" preserveAspectRatio="xMidYMid meet" >
                <g transform="translate(0.000000,125.000000) scale(0.100000,-0.100000)"  stroke="none">
                    <path d="M610 1155 l-155 -94 -5 69 -5 69 -222 -126 -223 -125 0 -474 0 -474 225 0 225 0 0 488 0 487 75 43 75 44 75 -47 75 -47 0 -484 0 -484 183 0 182 1 100 94 100 95 -57 0 -58 0 -2 392 -3 392 -210 137 c-116 76 -212 138 -215 138 -3 1 -75 -42 -160 -94z"/>
                    <path d="M1590 750 l0 -40 65 0 65 0 0 -150 0 -150 45 0 45 0 0 150 0 150 65 0 65 0 0 40 0 40 -175 0 -175 0 0 -40z"/>
                    <path d="M1990 600 l0 -190 45 0 45 0 0 75 0 75 80 0 80 0 0 -75 0 -75 45 0 45 0 0 190 0 190 -45 0 -45 0 0 -75 0 -76 -77 3 -78 3 -3 73 -3 72 -44 0 -45 0 0 -190z"/>
                    <path d="M2420 600 l0 -190 155 0 155 0 0 40 0 40 -110 0 -110 0 0 35 0 35 75 0 75 0 0 40 0 40 -75 0 -75 0 0 35 0 35 100 0 100 0 0 40 0 40 -145 0 -145 0 0 -190z"/>
                    <path d="M2930 600 l0 -190 45 0 45 0 0 122 0 122 86 -122 c81 -116 87 -122 120 -122 l34 0 0 190 0 190 -40 0 -39 0 -3 -107 -3 -108 -73 108 -73 107 -50 0 -49 0 0 -190z"/>
                    <path d="M3360 600 l0 -190 155 0 155 0 0 40 0 40 -110 0 -110 0 0 35 0 35 70 0 70 0 0 40 0 40 -70 0 -70 0 0 35 0 35 100 0 100 0 0 40 0 40 -145 0 -145 0 0 -190z"/>
                    <path d="M3700 783 c0 -4 24 -89 53 -188 l53 -180 41 -3 42 -3 21 73 c12 40 26 90 32 111 6 20 14 37 17 37 3 0 20 -48 37 -108 l31 -107 44 -3 45 -3 48 173 c27 95 51 181 53 191 5 14 -2 17 -39 17 l-44 0 -29 -110 c-15 -61 -31 -108 -34 -105 -3 4 -20 52 -36 108 l-30 102 -41 3 -41 3 -28 -98 c-15 -54 -30 -104 -34 -111 -5 -8 -20 32 -37 95 l-29 108 -48 3 c-26 2 -47 -1 -47 -5z"/>
                    <path d="M4280 600 l0 -190 113 1 c140 0 176 11 200 61 22 45 16 86 -17 117 l-24 22 25 25 c21 21 24 32 20 61 -12 74 -55 93 -207 93 l-110 0 0 -190z m194 110 c18 -7 26 -17 26 -35 0 -29 -24 -38 -91 -33 -37 3 -44 7 -47 26 -6 40 5 52 47 52 22 0 51 -4 65 -10z m20 -153 c11 -8 17 -23 14 -38 -3 -23 -7 -24 -75 -27 l-73 -3 0 34 c0 18 3 37 7 40 12 12 109 7 127 -6z"/>
                    <path d="M4680 600 l0 -190 155 0 155 0 0 40 0 40 -110 0 -110 0 0 35 0 35 75 0 75 0 0 40 0 40 -75 0 -75 0 0 35 0 35 100 0 100 0 0 40 0 40 -145 0 -145 0 0 -190z"/>
                    <path d="M5060 600 l0 -190 40 0 39 0 3 78 3 77 35 0 c33 0 37 -4 80 -77 l45 -78 48 0 c26 0 47 2 47 4 0 2 -20 39 -45 82 l-44 79 23 12 c31 16 50 65 43 109 -12 75 -55 94 -207 94 l-110 0 0 -190z m220 91 c17 -34 -13 -52 -79 -49 -55 3 -56 3 -59 36 l-3 32 65 0 c54 0 67 -3 76 -19z"/>
                    <path d="M5460 600 l0 -190 45 0 45 0 0 80 0 80 34 0 c32 0 37 -5 80 -80 l47 -80 44 0 c25 0 45 3 45 6 0 4 -18 39 -40 79 l-40 73 30 30 c25 26 30 38 30 80 0 39 -5 54 -25 74 -32 32 -62 38 -191 38 l-104 0 0 -190z m223 88 c19 -30 -8 -48 -74 -48 l-59 0 0 35 0 36 61 -3 c45 -2 65 -8 72 -20z"/>
                    <path d="M5810 785 c0 -3 32 -53 70 -110 l70 -104 0 -81 0 -80 50 0 50 0 0 78 0 77 65 103 c36 57 65 108 65 113 0 5 -20 9 -45 9 -44 0 -45 -1 -88 -65 l-44 -66 -41 66 -41 65 -56 0 c-30 0 -55 -2 -55 -5z"/>
                </g>
            </svg> 
        </a>
    </NogoCss>
} 
export default Nogo

const NogoCss = styled.div`
    flex-basis: 5vh;

    background: var(--bgTwo);
    margin: 2px;
    border: 1px solid var(--textTitle);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    a {
        flex: 1;
        width: 100%;
        height: 100%;
    }
    svg {
        width: 100%;
        height: 5vh;   
        margin: 1vh auto 0 auto;
    }
    a svg {
        
        transition: 0.2s;
        fill: var(--textTitle);
    }
    a:hover svg {
        fill: var(--textNormal);
    }
`